import { Mutex } from 'async-mutex';

import { removeTokens } from '@@helpers/transport/tokens';
import intercom from '@@services/intercom';

import { AuthBase } from '@@types/auth';

export const doLogoutRoutine = (data?: AuthBase) => {
  intercom.clearChat();

  if (!data || !data.isAuthenticated) {
    removeTokens();
  }

  window.location.href = '/login';
};

export const mutex = new Mutex();
