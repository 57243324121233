_toPairs = require('lodash/toPairs')
_find = require('lodash/find')
_assign = require('lodash/assign')
{fetchRequest} = require("@@services/transport/fetch")

{pubsubhub} = require "lib/helpers"

NAME =
    WHATSAPP_DEEPLINK: "whatsapp-deeplink"
    VIBER_DEEPLINK: "viber-deeplink"
    VIN_LOOKUP: "vin-lookup"
    IMEI_LOOKUP: "imei-lookup"
    CARPLATE_LOOKUP: "carplate-lookup-ua"

EVENT =
    ON_CONFIG_REFRESH: "config_refresh"

hub = pubsubhub()

isActive = (name) ->
    {is_active} = module.exports['integrations'][name] or {}
    !!is_active

setState = (state) ->
    for own k, v of state
        module.exports[k] = v

    hub.pub(
        EVENT.ON_CONFIG_REFRESH,
        module.exports
    )

    module.exports

getIntegrations = -> module.exports['integrations']

findIntegrationById = (id) ->
    integrations = _toPairs(getIntegrations())
    _iterator = ([_, intg]) -> intg.integration_id == id

    _find(integrations, _iterator)

findPrepaymentIntegrations = () -> 
    integrations = _toPairs(getIntegrations())
    _iterator = ([_, intg]) -> 
        intg.is_active and intg?.spec_data?.available_methods?.prepaid_admission

    integrations.filter(_iterator).map(([spec_name, integration]) ->
        _assign({}, integration, {spec_name})
    )


update = (cb) ->
    fetchRequest({
        url: "/settings/integrations"
        sccb: ({integrations}) -> cb setState integrations
    })


module.exports = {
    NAME, getIntegrations, setState, update,
    isActive, findIntegrationById, findPrepaymentIntegrations, hub, EVENT
}
