import { createApi } from '@reduxjs/toolkit/query/react';
import { RawAxiosRequestHeaders } from 'axios';

import { chatServiceInstance } from '@@services/transport/axios';

import type { AxiosBaseQuery, BaseQueryError } from '@@types/api';
import { CompanyConfig, SystemConfig } from '@@types/configs';

const axiosBaseQuery = (): AxiosBaseQuery => async (axiosConfig) => {
  try {
    const { ID } = require<CompanyConfig>('interface/Company');
    const { LOCALE } = require<SystemConfig>('interface/System');

    const extraHeaders: RawAxiosRequestHeaders = {
      'X-Company-id': ID,
      'X-Language': LOCALE,
    };

    // eslint-disable-next-line no-param-reassign
    axiosConfig.extraHeaders = extraHeaders;

    const { data, ...meta } = await chatServiceInstance(axiosConfig);

    return { data, meta };
  } catch (axiosError) {
    return {
      error: axiosError as BaseQueryError,
    };
  }
};

const api = createApi({
  reducerPath: 'chatService/api',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    'ClientConversations',
    'MessageTemplates',
    'ConversationTags',
    'ConversationChannels',
  ],
  endpoints: () => ({}),
});

export default api;
