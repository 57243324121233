export const GLOBAL_BRANCH_ID = -1;

export const BRANCH_COLORS = [
  '#DCDCDC',
  '#D21020',
  '#F5BE3F',
  '#3AB57B',
  '#269DE2',
  '#4054B3',
  '#8D2CA7',
  '#616161',
  '#7A88C9',
  '#F2522C',
  '#E47E76',
  '#1A7F45',
] as const;

export const BRANCH_TYPE = {
  ALL: 'all',
  SELECTED: 'selected',
} as const;
