export const TAXES_RULE_TYPES = {
  ORDER: 'ORDER',
  POSTING: 'POSTING',
} as const;

export const TAXES_RULE_TYPES_ENUM = {
  ORDER: 'order',
  POSTING: 'posting',
} as const;

export const OBJECT_TYPE = {
  TAX: 'tax',
  TAX_GROUP: 'taxGroup',
} as const;

export const TAXES_TYPES = {
  IMPOSED: 'imposed',
  INVESTED: 'invested',
  FIXED: 'fixed',
} as const;

export const RECALCULATE_TYPES = {
  ASK_FOR_EVERY_DOCUMENT: 'askForEveryDocument',
  ALWAYS_RECALCULATE: 'alwaysRecalculate',
  DONT_RECALCULATE_DONT_ASK: 'dontRecalculateDontAsk',
} as const;

export const SHOULD_RECALCULATE_TAX_FIELD = 'should_recalculate_taxes';

export const EXCEPTION_TYPES = {
  CATEGORY: 'category',
  ITEM: 'entity',
} as const;
