import SvgDayOff from '@@assets/images/icons/day_off.svg';
import SvgSick from '@@assets/images/icons/sick.svg';
import SvgVacation from '@@assets/images/icons/vacation.svg';

import { ICON_FILL } from '@@constants/images';

export const RANGE = {
  WEEK: 'week',
  MONTH: 'month',
};

export const SCALE = {
  LESS: 0,
  MORE: 1,
};

export const DAY_TYPE = {
  WORK: 0,
  VACATION: 1,
  SICK: 2,
  DAY_OFF: 3,
  NON_WORK: 4,
};

export const NON_WORKING_DAY_ICON = {
  [DAY_TYPE.VACATION]: SvgVacation,
  [DAY_TYPE.SICK]: SvgSick,
  [DAY_TYPE.DAY_OFF]: SvgDayOff,
};

export const NON_WORKING_DAY_ICON_FILL = {
  [DAY_TYPE.VACATION]: ICON_FILL.GREEN_100,
  [DAY_TYPE.SICK]: ICON_FILL.RED_100,
  [DAY_TYPE.DAY_OFF]: ICON_FILL.ORANGE_100,
};

export const COUNTER = {
  DAYS: 'days',
  HOURS: 'hours',
};
