import type { AxiosRequestConfig } from 'axios';

import api from '@@api/rtk';

import { ReduxAppInfoState } from '@@types/appInfo';
import {
  DoLoginGoogle,
  DoLoginOtpTwoFactorAuth,
  GoogleAuthArgs,
  OtpTwoFactorAuthSettings,
  SignUpDetailsArgs,
  SignUpGoogle,
  SignUpGoogleArgs,
  SignupStep,
  UserLogin,
} from '@@types/auth';

export const authApi = api.injectEndpoints({
  endpoints: (build) => ({
    signUpDetails: build.mutation<
      { signupStep: SignupStep; isAuthenticated: boolean },
      AxiosRequestConfig<SignUpDetailsArgs>
    >({
      query: (config) => {
        return {
          url: '/signup/step2',
          method: 'post',
          ...config,
        };
      },
    }),
    doLoginGoogle: build.mutation<
      DoLoginGoogle,
      AxiosRequestConfig<GoogleAuthArgs>
    >({
      query: (config) => {
        return {
          url: '/do-login-google',
          method: 'post',
          ...config,
        };
      },
    }),
    signUpGoogle: build.mutation<
      SignUpGoogle,
      AxiosRequestConfig<SignUpGoogleArgs>
    >({
      query: (config) => {
        return {
          url: '/signup/step1-google',
          method: 'post',
          ...config,
        };
      },
    }),
    doLogin: build.mutation<ReduxAppInfoState, AxiosRequestConfig<UserLogin>>({
      query: (config) => {
        return {
          url: '/do-login',
          method: 'post',
          ...config,
        };
      },
    }),
    prepareOtpTwoFactorAuth: build.mutation<{ otpUri: string }, void>({
      query: () => {
        return {
          url: '/settings/auth/2fa/prepare',
          method: 'post',
        };
      },
    }),
    enableOtpTwoFactorAuth: build.mutation<
      OtpTwoFactorAuthSettings,
      AxiosRequestConfig<{ otp: string }>
    >({
      query: (config) => {
        return {
          url: '/settings/auth/2fa/enable',
          method: 'post',
          ...config,
        };
      },
      invalidatesTags: ['SecuritySettings'],
    }),
    disableOtpTwoFactorAuth: build.mutation<
      Pick<OtpTwoFactorAuthSettings, 'is2faEnabled'>,
      AxiosRequestConfig<{ otp: string }>
    >({
      query: (config) => {
        return {
          url: '/settings/auth/2fa/disable',
          method: 'post',
          ...config,
        };
      },
      invalidatesTags: ['SecuritySettings'],
    }),
    doLoginOtpTwoFactor: build.mutation<
      DoLoginOtpTwoFactorAuth,
      AxiosRequestConfig<{ otp: string; login: string; password: string }>
    >({
      query: (config) => {
        return {
          url: '/do-login-otp',
          method: 'post',
          ...config,
        };
      },
    }),
  }),
});

export const {
  useSignUpDetailsMutation,
  useDoLoginGoogleMutation,
  useSignUpGoogleMutation,
  useDoLoginMutation,
  usePrepareOtpTwoFactorAuthMutation,
  useEnableOtpTwoFactorAuthMutation,
  useDisableOtpTwoFactorAuthMutation,
  useDoLoginOtpTwoFactorMutation,
} = authApi;

export default authApi;
