import { INSTANCE_TYPE } from '@@constants/instanceTypes';

import { Employee } from '@@types/employee';
import { AttachmentType } from '@@types/ui';
import { EventClientResponseError, SocketEvent } from '@@types/webSockets';

export enum SourceType {
  facebook = 'facebook',
  instagram = 'instagram',
  echatTelegram = 'echat-telegram',
  echatViber = 'echat-viber',
  twilioWhatsapp = 'twilio-whatsapp',
  twilioSms = 'twilio-sms',
  telegramBot = 'telegram-bot',
}

export enum ChannelAccessLevel {
  onlyMe,
  all,
  selectedEmployees,
}

export interface ChannelPermission {
  accessLvl: ChannelAccessLevel;
  employee?: number;
}

export enum ConversationStatus {
  active = 'active',
  closed = 'closed',
  deleted = 'deleted',
  initiated = 'initiated',
  initiatedByClient = 'initiated_by_client',
}

export enum ConversationReadStatus {
  unread = 'unread',
  readWithResponse = 'read_with_response',
  readWithoutResponse = 'read_without_response',
}

export enum RelativeFilterType {
  all,
  my,
  notAssigned,
}

export enum MessageStatus {
  pending = 'pending',
  delivered = 'delivered',
  sent = 'sent',
  received = 'received',
  error = 'error',
  deleted = 'deleted',
}

export interface ChannelAttribute<AttributeValue = unknown> {
  attrType: string;
  value: AttributeValue;
}

export enum ChannelStatus {
  active = 'active',
  inactive = 'inactive',
  deleted = 'deleted',
  error = 'error',
  synchronization = 'synchronization',
}

export interface Channel {
  id: number;
  name: string;
  username: string | null;
  connectionSource: number;
  sourceType: SourceType;
  channelId: string;
  accountSid?: string;
  messagingServiceSid?: string;
  status: ChannelStatus;
  createdAt: number;
  updatedAt: number;
  permissions: ChannelPermission[];
  createdBy: { username: string };
  allowComments: boolean;
  commentRefreshNeed: boolean;
}

export interface RedirectUri {
  redirectUri: string;
}

export interface RedirectUrl {
  redirectUrl: string;
}

export interface FacebookPage {
  id: string;
  name: string;
  canConnect: boolean;
}

export interface InstagramAccount {
  id: string;
  pageId: string;
  name: string | null;
  username: string;
  canConnect: boolean;
}

export interface TwilioToken {
  id: number;
  createdBy: number;
}

export interface CursorsPaging {
  next?: string;
  previous?: string;
}

export interface AttachmentLinkPayload {
  url: string | null;
  mediaAssetId?: string;
  mediaFile?: string;
}

export interface AttachmentAudioLinkPayload extends AttachmentLinkPayload {
  isTranscribed: boolean;
  // extra fields, front generate and use.
  transcription?: string;
  error?: string;
}

export interface AttachmentLocationPayload {
  latitude: string;
  longitude: string;
}

export interface AttachmentFallbackPayload {
  title: string;
}

export interface AttachmentIdPayload {
  id: string;
}

export interface AttachmentAudioIdPayload extends AttachmentIdPayload {
  isTranscribed: boolean;
}

export interface S3Attachment {
  id: string;
  fileType: AttachmentType;
  mediaType: string;
  fileName: string;
  sourceType: SourceType;
  size: number;
  channelId: number;
  companyId: number;
  employeeId: number;
  hashsum: string;
  createdAt: string;
  path: string;
}

export interface UploadAttachment {
  id: string;
  file: File;
  isLoading: boolean;
  // from s3
  s3?: S3Attachment;
}

export interface MetaAttachment<
  Payload =
    | AttachmentLinkPayload
    | AttachmentAudioLinkPayload
    | AttachmentLocationPayload
    | AttachmentFallbackPayload
    | AttachmentIdPayload
    | AttachmentAudioIdPayload,
> {
  type: AttachmentType;
  payload: Payload;
}

// This attachment type is returned via websockets if a link is sent through https://business.facebook.com
export interface MetaBussinesFallbackAttachment {
  type: AttachmentType.fallback;
  title: string;
}

export interface ConversationChannel {
  id: string;
  channelId: string;
  name: string;
  username: string | null;
  status: ChannelStatus;
  createdBy: number;
  updatedBy: number;
  resourceId: number;
  sourceType: SourceType;
}

export interface User {
  id: string;
  sourceType: SourceType;
  userId: string;
  name?: string;
  username?: string;
  profilePicture?: string;
  phone?: string;
}

export enum SystemAction {
  active = 'active',
  closed = 'closed',
  addConversationToClient = 'add_conversation_to_client',
  removeConversationFromClient = 'remove_conversation_from_client',
  assignEmployeeToConversation = 'assign_employee_to_conversation',
  unassignEmployeeFromConversation = 'unassign_employee_from_conversation',
  autoAssignEmployeeToConversation = 'auto_assign_employee_to_conversation',
}

export interface SystemMessage {
  systemAction: SystemAction;
}

export interface ClientBindingSystemMessage extends SystemMessage {
  systemAction:
    | SystemAction.addConversationToClient
    | SystemAction.removeConversationFromClient;
  clientId: number;
}

export interface AssignEmployeeSystemMessage extends SystemMessage {
  systemAction: SystemAction.assignEmployeeToConversation;
  employeeId: number;
}

export interface ReplyTo {
  mid: string;
}

export interface ReplyToStory {
  story: {
    id: string | null;
    url: string | null;
  };
}

// Messenger doc - https://developers.facebook.com/docs/messenger-platform/reference/webhook-events/messages
// Instagram doc - https://developers.facebook.com/docs/messenger-platform/instagram/features/webhook/
export interface MetaMessage<Reply = ReplyTo | ReplyToStory> {
  isEcho?: boolean;
  isUnsupported?: boolean;
  appId?: number;
  text?: string;
  attachments?: Array<MetaAttachment | MetaBussinesFallbackAttachment>;
  replyTo?: Reply;
  contentSid?: string;
  contentVariables?: Record<string, string>;
  postId?: string;
}

export interface Message<SourceMessage = MetaMessage | SystemMessage> {
  id: string;
  sender: string;
  recipient: string;
  timestamp: number;
  message: SourceMessage;
  messageId: string | null;
  source: SourceType;
  conversationId: string;
  channelId: ConversationChannel['id'];
  userId: User['id'];
  employeeId: number | null;
  status: MessageStatus;
  // extra fields, front generate and use.
  sendId?: string;
  isFrozenError?: boolean;
  isCommentReply?: boolean;
}

export type ConversationMessage = Pick<
  Message,
  | 'id'
  | 'message'
  | 'messageId'
  | 'sender'
  | 'recipient'
  | 'timestamp'
  | 'employeeId'
>;

export interface MessageTemplate {
  id: string;
  title: string;
  message: string;
  companyId: number;
}

export type MessageTemplatePreview = Omit<MessageTemplate, 'message'> & {
  messagePreview: string;
};

export interface ConversationClient {
  id: number;
  companyId: number;
}

export interface ConversationClientDocument {
  createdAt: number;
  branchId: number;
  idLabel: string;
  documentDbId: number;
  branchIcon: number;
  branchColor: number;
  documentType:
    | typeof INSTANCE_TYPE.LEAD
    | typeof INSTANCE_TYPE.ORDER
    | typeof INSTANCE_TYPE.ESTIMATE;
  statusName: string;
  statusColor: string;
  amount: number;
}

export interface ConversationTag {
  id: string;
  name: string;
  colorHex: string;
  isDeleted?: boolean;
}

export type ConversationParticipants = [
  ConversationChannel['channelId'],
  User['userId'],
];

export interface Conversation {
  id: string;
  clientId?: number;
  employeeId: number | null;
  userId: User['id'];
  channelId: ConversationChannel['id'];
  lastMessageId: ConversationMessage['id'] | null;
  sourceType: SourceType;
  status: ConversationStatus;
  tagIds: ConversationTag['id'][];
  participants: ConversationParticipants;
  readTimestamp: number | null;
  seenTimestamp: number | null;
  updatedAt: number;
  // extra fields, front generate and use.
  isNew?: boolean;
}

export enum ConversationFilterField {
  status = 'status',
  channelId = 'channelId',
  employeeId = 'employeeId',
  tagIds = 'tagIds',
}

export type ConversationFilter<
  Field extends ConversationFilterField = ConversationFilterField,
  Value = unknown,
> = {
  field: Field;
  value: Value;
  operator?: 'in';
};

export type ConversationFilters = {
  [ConversationFilterField.status]: ConversationFilter<
    ConversationFilterField.status,
    ConversationStatus
  >;
  [ConversationFilterField.channelId]: ConversationFilter<
    ConversationFilterField.channelId,
    ConversationChannel['id'][]
  >;
  [ConversationFilterField.employeeId]: ConversationFilter<
    ConversationFilterField.employeeId,
    Employee['id'] | null | (Employee['id'] | null)[]
  >;
  [ConversationFilterField.tagIds]: ConversationFilter<
    ConversationFilterField.tagIds,
    (ConversationTag['id'] | null)[]
  >;
};

export enum TelegramBotAttributeType {
  welcomeMessage = 'welcome-message',
  menuTitle = 'menu-title',
  shareContact = 'share-contact',
  button = 'button',
}

export enum TelegramBotAttributeButtonType {
  chatRequest = 'chat-request',
  custom = 'custom',
}

export interface TelegramBotAttributeValue {
  title?: string;
  response?: string;
  order?: number;
  buttonType?: TelegramBotAttributeButtonType;
  callbackId?: string;
}

export interface ListData<Item> {
  result: Item[];
  paging: CursorsPaging;
  limit: number;
}

export type MessageListData = ListData<Message>;

export type ConversationListData = ListData<Conversation>;

export type UserListData = ListData<User>;

export type ChannelListData = ListData<ConversationChannel>;

export type TagListData = ListData<ConversationTag>;

export interface ConversationCountData {
  totalCount: number;
}

export type ConversationReadData = Pick<Conversation, 'id' | 'readTimestamp'>;

export type ConversationBlockData = Pick<Conversation, 'id'> & {
  isBlocked: boolean;
};

export type MessageSendData = Partial<
  Pick<Message, 'id' | 'messageId' | 'status'>
>;

export interface MessageStartData {
  conversationId: string;
}

export interface MessageTranscribedData {
  messageId: Message['id'];
  transcription: string;
}

export interface BindConversationData {
  conversationId: string;
}

export interface AssignEmployeeData {
  employeeId: number | null;
}

export interface MediaAssetPreviewData {
  id: string;
  path: string;
}

export interface MediaFilePreviewData {
  id: string;
  publicUrl: string;
}

export interface ConversationReplaceData {
  deleteConversationId: string;
  newConversationId: string;
}

export interface ConversationTagAssignmentData {
  tagId: ConversationTag['id'];
  conversationId: Conversation['id'];
}

export type ConversationListSocketEvent = RequiredBy<
  SocketEvent<ConversationListData>,
  'eventId'
>;

export type ConversationCountSocketEvent = SocketEvent<ConversationCountData>;

export type ConversationSocketEvent = SocketEvent<Conversation>;

export type ConversationReadSocketEvent = SocketEvent<ConversationReadData>;

export type ConversationBlockSocketEvent = SocketEvent<ConversationBlockData>;

export type UserListSocketEvent = SocketEvent<UserListData>;

export type TagListSocketEvent = SocketEvent<TagListData>;

export type MessageSocketEvent = SocketEvent<Message>;

export type MessageDeletedSocketEvent = SocketEvent<Pick<Message, 'id'>>;

export type MessageTranscribeSocketEvent = SocketEvent<
  undefined,
  { messageObjId: Message['id']; errors: string[] }
>;

export type MessageTranscribedSocketEvent = SocketEvent<MessageTranscribedData>;

export type ConversationDeletedSocketEvent = SocketEvent<
  Pick<Conversation, 'id'>
>;

export type ConversationUpdateSocketEvent = RequiredBy<
  SocketEvent<Conversation>,
  'eventId'
>;

export type MessageListSocketEvent = RequiredBy<
  SocketEvent<MessageListData>,
  'eventId'
>;

export type MessageSendSocketEvent = RequiredBy<
  SocketEvent<
    MessageSendData,
    EventClientResponseError & { messageObjId?: Message['id'] }
  >,
  'eventId'
>;

export type MessageStartSocketEvent = RequiredBy<
  SocketEvent<
    MessageStartData,
    EventClientResponseError & { messageObjId: Message['id'] }
  >,
  'eventId'
>;

export type MessageGetStoryLinkSocketEvent = SocketEvent<{
  link: string | null;
}>;

export type MediaAssetGetPreviewEvent = SocketEvent<MediaAssetPreviewData>;

export type MediaFileGetPreviewEvent = SocketEvent<MediaFilePreviewData>;

export type ConversationReplaceSocketEvent =
  SocketEvent<ConversationReplaceData>;

export type TgBotSetAudioUrlEvent = RequiredBy<
  SocketEvent<
    AttachmentAudioIdPayload & {
      url: string;
    }
  >,
  'data'
>;

export type TgBotSetMediaUrlEvent = RequiredBy<
  SocketEvent<Pick<AttachmentIdPayload, 'id'> & { url: string }>,
  'data'
>;

export type ChannelListSocketEvent = SocketEvent<ChannelListData>;

export type ConversationTagUpdateSocketEvent = SocketEvent<ConversationTag>;

export type ConversationTagDeleteSocketEvent = SocketEvent<
  Pick<ConversationTag, 'id'>
>;

export type ConversationTagAssignSocketEvent = SocketEvent<{
  tag: ConversationTag;
  conversationId: Conversation['id'];
}>;

export type ConversationTagUssignSocketEvent =
  SocketEvent<ConversationTagAssignmentData>;

export interface ConversationGetByUserArgs {
  userId: User['userId'];
  channelId: ConversationChannel['channelId'];
  source: SourceType;
}

export type CreateMetaChannelArgs = Pick<
  Channel,
  'connectionSource' | 'channelId' | 'permissions'
> & { pageId?: string; isCommentsAllowed: boolean };

export type CreateEChatChannelArgs = Pick<
  Channel,
  'name' | 'channelId' | 'permissions'
> & { apiToken: string };

export type CreateTwilioChannelArgs = Pick<
  Channel,
  | 'name'
  | 'connectionSource'
  | 'channelId'
  | 'permissions'
  | 'messagingServiceSid'
>;

export type CreateTelegramBotChannelArgs = Pick<Channel, 'permissions'> & {
  apiToken: string;
};

export interface AddTelegramBotAttributeArgs {
  channelAttributeValues: ChannelAttribute<TelegramBotAttributeValue>[];
}

export interface ConnectTwilioArgs {
  authToken: string;
  accountSid: string;
}

export type UpdateMetaChannelArgs = Pick<
  Channel,
  'connectionSource' | 'permissions'
> & { isCommentsAllowed: boolean };

export type UpdateEChatChannelArgs = Pick<
  Channel,
  'name' | 'connectionSource' | 'permissions'
>;

export type UpdateTwilioChannelArgs = Pick<
  Channel,
  'name' | 'connectionSource' | 'permissions' | 'messagingServiceSid'
>;

export type UpdateTelegramBotChannelArgs = Pick<
  Channel,
  'connectionSource' | 'permissions'
>;
