import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import {
  Conversation,
  ConversationChannel,
  ConversationFilters,
  ConversationReplaceData,
  ConversationStatus,
  ConversationTag,
  Message,
  RelativeFilterType,
  User,
} from '@@types/chat';

export const conversationsAdapter = createEntityAdapter({
  selectId: (conversation: Conversation) => conversation.id,
});

export const usersAdapter = createEntityAdapter({
  selectId: (user: User) => user.id,
});

export const channelsAdapter = createEntityAdapter({
  selectId: (channel: ConversationChannel) => channel.id,
});

export const tagsAdapter = createEntityAdapter({
  selectId: (tag: ConversationTag) => tag.id,
});

export const messagesAdapter = createEntityAdapter({
  selectId: (message: Message) => message.id,
});

interface ChatsState {
  count: number;
  loadingEvents: Record<string, boolean>;
  filters: {
    statusFilter: ConversationStatus;
    relativeFilter: RelativeFilterType;
    filters?: Partial<ConversationFilters>;
  };
  conversations: ReturnType<typeof conversationsAdapter.getInitialState>;
  users: ReturnType<typeof usersAdapter.getInitialState>;
  channels: ReturnType<typeof channelsAdapter.getInitialState>;
  tags: ReturnType<typeof tagsAdapter.getInitialState>;
  messages: ReturnType<typeof messagesAdapter.getInitialState>;
}

export const initialState: ChatsState = {
  count: 0,
  loadingEvents: {},
  filters: {
    statusFilter: ConversationStatus.active,
    relativeFilter: RelativeFilterType.all,
  },
  conversations: conversationsAdapter.getInitialState(),
  users: usersAdapter.getInitialState(),
  channels: channelsAdapter.getInitialState(),
  tags: tagsAdapter.getInitialState(),
  messages: messagesAdapter.getInitialState(),
};

const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    startLoadingEvent(state, { payload }: { payload: string }) {
      state.loadingEvents[payload] = true;
    },
    restartLoadingEvents(state, { payload }: { payload: string }) {
      state.loadingEvents = { [payload]: true };
    },
    setStatusFilter(state, { payload }: { payload: ConversationStatus }) {
      state.filters.statusFilter = payload;
    },
    setRelativeFilter(state, { payload }: { payload: RelativeFilterType }) {
      state.filters.relativeFilter = payload;
    },
    setFilters(state, { payload }: { payload: Partial<ConversationFilters> }) {
      state.filters.filters = payload;
    },
    setConversationsCount(state, { payload }: { payload: number }) {
      state.count = payload;
    },
    // we can only increment the total count, for decrement need request conversation.count
    // because conversation.count returns a maximum of 100.
    // if real conversation count is for ex. 150, decrement will not work correctly
    increaseConversationCount(state) {
      state.count += 1;
    },
    setConversation(state, { payload }: { payload: Conversation }) {
      state.conversations.entities[payload.id] = payload;
    },
    setConversationsList(
      state,
      {
        payload: { eventId, conversations },
      }: { payload: { eventId: string; conversations: Conversation[] } },
    ) {
      delete state.loadingEvents[eventId];

      // do not use upsertMany here, because it won`t update the list of ids
      // if conversation is already in the entities
      conversations.forEach((conversation) => {
        const { id } = conversation;

        state.conversations.entities[id] = conversation;
        state.conversations.ids.push(id);
      });
    },
    // upsertConversation is used to add conversation to the list of conversations
    // if it's not there yet
    upsertConversation(state, { payload }: { payload: Conversation }) {
      conversationsAdapter.upsertOne(state.conversations, payload);

      if (!state.conversations.ids.includes(payload.id)) {
        state.conversations.ids.push(payload.id);
      }
    },
    // updateConversation is used to update conversation data
    // without adding it to the list of conversations
    updateConversation(
      state,
      { payload }: { payload: RequiredBy<Partial<Conversation>, 'id'> },
    ) {
      conversationsAdapter.updateOne(state.conversations, {
        id: payload.id,
        changes: payload,
      });
    },
    replaceConversationId(
      state,
      { payload }: { payload: ConversationReplaceData },
    ) {
      const oldConversation =
        state.conversations.entities[payload.deleteConversationId];

      if (oldConversation) {
        state.conversations.entities[payload.newConversationId] = {
          ...oldConversation,
          id: payload.newConversationId,
        };
      }
    },
    replaceNewConversationWithExisting(
      state,
      { payload }: { payload: ConversationReplaceData },
    ) {
      const oldConversation =
        state.conversations.entities[payload.deleteConversationId];

      if (oldConversation) {
        state.conversations.entities[payload.newConversationId] = {
          ...oldConversation,
          id: payload.newConversationId,
          isNew: undefined,
        };
      }
    },
    removeConversationFromList(
      state,
      { payload }: { payload: Conversation['id'] },
    ) {
      state.conversations.ids = state.conversations.ids.filter(
        (id) => id !== payload,
      );
    },
    assignEmployee(
      state,
      {
        payload,
      }: {
        payload: {
          conversationId: Conversation['id'];
          employeeId: Conversation['employeeId'];
        };
      },
    ) {
      const conversation = state.conversations.entities[payload.conversationId];

      if (conversation) {
        conversation.employeeId = payload.employeeId;
      }
    },
    assignConversationTag(
      state,
      {
        payload,
      }: {
        payload: {
          conversationId: Conversation['id'];
          tag: ConversationTag;
        };
      },
    ) {
      const conversation = state.conversations.entities[payload.conversationId];

      if (conversation && !conversation.tagIds.includes(payload.tag.id)) {
        conversation.tagIds.push(payload.tag.id);
      }
    },
    unassignConversationTag(
      state,
      {
        payload,
      }: {
        payload: {
          conversationId: Conversation['id'];
          tagId: ConversationTag['id'];
        };
      },
    ) {
      const conversation = state.conversations.entities[payload.conversationId];

      if (conversation) {
        if (conversation.tagIds.includes(payload.tagId)) {
          conversation.tagIds = conversation.tagIds.filter(
            (tagId) => tagId !== payload.tagId,
          );
        }
      }
    },
    updateConversationTag(state, { payload }: { payload: ConversationTag }) {
      tagsAdapter.updateOne(state.tags, {
        id: payload.id,
        changes: payload,
      });
    },
    removeConversationTag(
      state,
      {
        payload,
      }: {
        payload: {
          tagId: ConversationTag['id'];
        };
      },
    ) {
      const { tagId } = payload;

      Object.values(state.conversations.entities).forEach((conversation) => {
        if (conversation.tagIds.includes(tagId)) {
          // eslint-disable-next-line no-param-reassign
          conversation.tagIds = conversation.tagIds.filter(
            (id) => id !== tagId,
          );
        }
      });
    },
    omitConversationClient(
      state,
      { payload }: { payload: Conversation['id'] },
    ) {
      const conversation = state.conversations.entities[payload];

      if (conversation) {
        delete conversation.clientId;
      }
    },
    cleanupConversationIdsList(state) {
      state.conversations.ids = [];
    },
    setUsersList(state, { payload }: { payload: User[] }) {
      usersAdapter.setMany(state.users, payload);
    },
    setUser(state, { payload }: { payload: User }) {
      usersAdapter.setOne(state.users, payload);
    },
    setChannelsList(state, { payload }: { payload: ConversationChannel[] }) {
      channelsAdapter.setMany(state.channels, payload);
    },
    setChannel(state, { payload }: { payload: ConversationChannel }) {
      channelsAdapter.setOne(state.channels, payload);
    },
    setTagsList(state, { payload }: { payload: ConversationTag[] }) {
      tagsAdapter.setMany(state.tags, payload);
    },
    setMessageList(state, { payload }: { payload: Message[] }) {
      messagesAdapter.setMany(state.messages, payload);
    },
  },
});

export const {
  startLoadingEvent,
  restartLoadingEvents,
  setStatusFilter,
  setRelativeFilter,
  setFilters,
  setConversationsCount,
  increaseConversationCount,
  setConversation,
  setConversationsList,
  upsertConversation,
  updateConversation,
  replaceConversationId,
  replaceNewConversationWithExisting,
  removeConversationFromList,
  assignEmployee,
  assignConversationTag,
  unassignConversationTag,
  updateConversationTag,
  removeConversationTag,
  omitConversationClient,
  cleanupConversationIdsList,
  setUsersList,
  setUser,
  setChannelsList,
  setChannel,
  setTagsList,
  setMessageList,
} = chatsSlice.actions;

export default chatsSlice.reducer;
