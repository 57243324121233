// Must be synced with remonline/relations/constants.py
export const RELATION_TYPE = {
  ORDER: 0,
  SALE: 1,
  REFUND: 2,
  INCOME: 3,
  OUTCOME: 4,
  MOVE: 5,
  GOODS_IMPORT: 6,
  SUPPLIER_REFUND: 7,
  INVOICE: 8,
  PAYROLL: 9,
  PAYMENT: 10,
  ESTIMATE: 11,
  LEAD: 12,
  CONVERSION: 14,
  BALANCE_CORRECTION: 90,
} as const;
