# Form fields core lib.

assign = require "lodash/assign"
flatten = require "lodash/flatten"
head = require "lodash/head"

{toInt, toBool, bool, trim} = require "lib/helpers"
{required, maxLength} = require "lib/plugins/Validate"
{error} = require "lib/logger"
{fetchRequest} = require("@@services/transport/fetch")
{setForbiddenAfterCreation} = require "lib/forms/transport"

# Field type.
CHECKBOX    = 0
TEXTFIELD   = 1
TEXTAREA    = 2
SELECT      = 3
DATETIME    = 4
DATE        = 5
NUMBER      = 6
AD_CAMPAIGN = 7
HEADER      = 8
COMPOSITE   = 9
DURATION    = 10
ASSET_HIERARCHY = 11
ORDER_SYSTEM = 12
HIERARCHY_TEXTFIELD = 13
SEARCHABLE_CATEGORY = 18
ASSET_FIELD = 19
RESOURCE = 20
PAYER = 21
PRODUCT_UOM = 22

# enum: field size.
# Should be the same as on backend.
SIZE = {
    F: 100
    M: 50
}

INPUT_LENGTH = {
    XXS: 8
    XS: 16
    S: 32
    M: 64
    L: 256
    XXL: 16384
}

TIMESTAMP_DATEPICKERS = ["will_done_at", "warranty_date", "assigned_at", "scheduled_for"]

# Field.
class TField

    # int: field id.
    id: -1

    # string: name.
    name: ""

    # string: title (friendly name.)
    title: ""

    # int: field widget id (see WIDGETS.)
    field_type: 0

    # int: book id value.
    book_id: 0

    # string: custom request url for load select options like a books.
    request_url: ""

    # string: name of ticket key (template variable.)
    ticket_key: ""

    # string: string contains available values.
    values: ""

    # bool: is field visible GLOBALY?
    # (globaly means outside of context of ordertypes, clienttypes etc.)
    visible: true

    # bool: is custom field? (not system.)
    custom: true

    # Placement: field placement.
    placement: null

    # bool: could edit the field?
    is_editable: false

    is_pro: false

    # Ctor.
    constructor: (kwargs={}) ->
        @id = toInt kwargs.id, @id
        @name = kwargs.name or @name
        @title = kwargs.title or @title
        @values = kwargs.values or @values
        @ticket_key = kwargs.ticket_key or @ticket_key
        @field_type = toInt kwargs.field_type, @field_type
        @visible = toBool kwargs.visible, @visible
        @book_id = toInt kwargs.book_id, @book_id
        @request_url = kwargs.request_url or @request_url
        @custom = toBool kwargs.custom, @custom
        @placement = kwargs.placement or @placement
        @is_editable = kwargs.is_editable or @is_editable
        @is_pro = kwargs.is_pro or @is_pro


#  Related form context.
class FormContext

    # object: entity id/value.
    entity_id: 0

    # string: entity name
    # (eg. ordertype_id|juridical)
    entity_name: ""

    # string: fieldtype
    # (eg. order|client|asset)
    fieldtype: ""

    # Constructor.
    constructor: (kwargs={}) ->
        {entity_id, fieldtype, entity_name} = kwargs
        @fieldtype = fieldtype or @fieldtype
        @entity_name = entity_name or @entity_name

        # Special handling, since entity_id can be False value,
        # eg. in case for clientfields when juridical is False.
        @entity_id = if entity_id is undefined then @entity_id else entity_id


# Field placement data.
class Placement

    # string: default value.
    default_value: ""

    # bool: is field required?
    required: false

    # int: field position.
    pos: 0

    # bool: Order Visibility flag.
    card_visible: false

    # bool: Disable editing after creating flag.
    is_forbidden_after_creation: false

    # bool: Forbidden deletion of field.
    is_forbidden_for_deletion: false

    # Constructor.
    constructor: (kwargs={}) ->
        @required = toBool kwargs.required, @required
        @card_visible = toBool kwargs.card_visible, @card_visible
        @is_forbidden_after_creation = toBool kwargs.is_forbidden_after_creation, @is_forbidden_after_creation
        @is_forbidden_for_deletion = toBool kwargs.is_forbidden_for_deletion, @is_forbidden_for_deletion
        @default_value = kwargs.default_value or @default_value
        @pos = toInt kwargs.pos, @pos


# TField service.
class IFieldSvc

    # Is given TField is system one?
    # -> TField field: field.
    # Returns bool: designator.
    isSystem: ({custom}) -> not custom

    # Returns [TField]: fields that are system ones.
    # -> [TField] fields: input fields array.
    filterSysFields: (fields) =>
        fields.filter (field) => @isSystem field

    # Returns [TField]: fields that are system ones.
    # -> [TField] fields: input fields array.
    filterUsrFields: (fields) =>
        fields.filter (field) => not @isSystem field

    # Returns [TField]: fields that are NOT headers.
    # -> [TField] fields: input fields array.
    filterNotHeaders: (fields) =>
        fields.filter ({field_type}) => field_type isnt HEADER

    # For all methods below:
    # Returns object: model for appropriate TField property.

    valuesModel: ->
        name = "values"; maxlength = 256
        schema: "#{name}": [required, maxLength maxlength]
        attribs: {name, maxlength}

    # Returns bool: is given model contains `required` validation?
    # -> object model: model to check (see valuesModel etc.)
    isPropRequired: (model) ->
        if (name = (model.attribs or { }).name) is undefined
            return error "Incorrect model"

        required in model.schema[name]

    # Returns bool: is given value is in allowed values?
    # -> string value: value to check.
    # -> string values: allowed values.
    isValueInRange: (value, values) ->
        # Empty string can't be validated.
        return true if value.length is 0

        # If value is within allowed values - it's OK, else - take a message.
        if value in new IFieldSvc().valuesToArray values then true
        else __("The specified value is missing in the list")

    # Converts field values string to array.
    # -> string str: input string.
    # Returns string[]: array of values.
    valuesToArray: (str) ->
        str.split ','
            # Kicking-out multiple commas.
            .filter (v) -> v isnt ""

            # Kickung-out leading, trailing wsp,
            # since pity humans always adds a whitespace after coma;
            # eg: Value1, Value2, ValueX ...
            .map (v) -> trim v

    # Returns bool: is book can be attached to given field?
    # -> TField field: target field.
    isBookAvailable: (field) -> true

    # Returns string: field data string.
    str: (field) ->
        base = [field.id]

        if friendly_name = field.title or field.name or undefined
            base.push ": "
            base.push friendly_name

        base.join ''

    # Returns [int]: ids of fields,
    # stored in composite value string.
    # -> string string: input string.
    parseCompositeIds: (string) =>
        return string.split(',').map toInt

    # Returns [int]: ids of fields,
    # that are placed into composite fields.
    # -> [TField] fields: source fields array.
    extractOnlyInCompositeIds: (fields) =>
        return flatten(
            fields
                .filter ({field_type}) => field_type is COMPOSITE
                .map ({values}) => @parseCompositeIds values
        )

    # Returns Promise: unbinds field from given context.
    # -> FormContext context: form context.
    # -> int field_id: target field id.
    unbindField: (context, field_id) =>
        new Promise (resolve, reject) ->
            {entity_id, fieldtype} = context

            fetchRequest({
                url: "/forms/unbind"
                payload: {
                    id: field_id
                    fieldtype
                    entity_id
                }
                sccb: (state) =>
                    require("lib/forms/cache").reCache context, state
                    resolve()

                ercb: reject
            })

    # Returns Promise: sets Field Required flag value for field.
    # -> FormContext context: form context.
    # -> int field_id: target field id.
    # -> bool requred: flag value.
    setFieldRequired: (context, field_id, required) =>
        new Promise (resolve, reject) ->
            {entity_id, fieldtype} = context

            fetchRequest({
                url: "/forms/set-required"
                payload: {
                    id: field_id
                    fieldtype
                    entity_id
                    required
                }
                sccb: (state) =>
                    require("lib/forms/cache").reCache context, state
                    resolve()

                ercb: reject
            })

    # Returns Promise: sets Order Visible flag value for field.
    # -> FormContext context: form context.
    # -> int field_id: target field id.
    # -> bool card_visible: flag value.
    setFieldCardVisible: (context, field_id, card_visible) =>
        new Promise (resolve, reject) ->
            {entity_id, fieldtype} = context

            fetchRequest({
                url: "/forms/set-card-visible"
                payload: {
                    id: field_id
                    fieldtype
                    entity_id
                    card_visible
                }
                sccb: (state) =>
                    require("lib/forms/cache").reCache context, state
                    resolve()

                ercb: reject
            })

    # Returns Promise: sets Edit disable flag value for field.
    # -> FormContext context: form context.
    # -> int field_id: target field id.
    # -> bool is_forbidden_after_creation: flag value.
    setFieldForbiddenAfterCreation: (context, field_id, is_forbidden_after_creation) =>
        new Promise (resolve, reject) ->
            {entity_id, fieldtype} = context

            setForbiddenAfterCreation(
              {
                  id: field_id
                  fieldtype
                  entity_id
                  is_forbidden_after_creation
              },
              {
                  sccb: (state) =>
                      require("lib/forms/cache").reCache context, state
                      resolve()

                  ercb: reject
              }
            )

    # Returns Promise: sets Field Default Value for field.
    # -> FormContext context: form context.
    # -> int field_id: target field id.
    # -> bool requred: flag value.
    setFieldDefault: (context, field_id, default_value) =>
        new Promise (resolve, reject) ->
            {entity_id, fieldtype} = context

            fetchRequest({
                url: "/forms/set-default"
                payload: {
                    id: field_id
                    fieldtype
                    entity_id
                    default_value
                }
                sccb: (state) =>
                    require("lib/forms/cache").reCache context, state
                    resolve()

                ercb: reject
            })

    # Returns Promise: retrieves visible fields
    # in terms of specified form context.
    # -> FormContext context: form context.
    listContextFields: (context) =>
        new Promise (resolve, reject) =>
            {fieldtype, entity_id, entity_name} = context

            isInContext = (dict) ->
                return true if entity_name == 'asset'
                return true if entity_name == 'product'
                dict[entity_name] is entity_id

            sccb = (data) =>
                require("lib/forms/cache").initCache context, data

                # Resultset contains ALL fields of company
                # (context entity insensitive, only fieldtype),
                # so we should filter current context fields only.
                context_fields = data.filter ({types}) =>
                    bool types.filter isInContext

                # Now, let's map over each context field
                # and build result object with field and context data.
                resolve context_fields.map (raw) =>
                    placement = new Placement head raw.types.filter isInContext
                    new TField assign {placement}, raw

            fetchRequest({
                url: "/forms/reload"
                payload: {fieldtype}
                isDuplicated: false
                ercb: reject
                sccb
            })

    # Returns Promise: reorders fields in context.
    # -> FormContext context: form context.
    # -> {int:int} ordering: mapping contains
    #    field id and it's new position.
    reorderFields: (context, ordering) =>
        new Promise (resolve, reject) =>
            {fieldtype, entity_id} = context

            fetchRequest({
                url: "/forms/reorder"
                method: "POST"
                payload: {
                    ordering: JSON.stringify ordering
                    fieldtype
                    entity_id
                }
                sccb: (state) =>
                    require("lib/forms/cache").initCache context, state
                    resolve()

                ercb: reject
            })

# Filters only visible fields.
onlyVisible = (fields) -> fields.filter (field) -> field.visible

# Stateless field service instance.
# Use it everywhere in project.
field_svc = new IFieldSvc()


module.exports = {
    CHECKBOX
    TEXTFIELD
    TEXTAREA
    SELECT
    DATETIME
    DATE
    NUMBER
    AD_CAMPAIGN
    ASSET_HIERARCHY
    HIERARCHY_TEXTFIELD
    ORDER_SYSTEM
    ASSET_FIELD
    HEADER
    COMPOSITE
    DURATION
    TIMESTAMP_DATEPICKERS
    SIZE
    INPUT_LENGTH
    SEARCHABLE_CATEGORY
    RESOURCE
    PAYER
    PRODUCT_UOM
    TField
    FormContext
    Placement
    IFieldSvc
    field_svc
    onlyVisible
}
