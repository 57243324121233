import { Client } from '@@types/client';
import { RelatedDocument } from '@@types/relation';

export enum PaymentSystem {
  ropay = 'ropay',
  stripe = 'stripe',
  liqpay = 'liqpay',
}

export enum ZhabkaMerchantType {
  fop = 'fop',
  tov = 'tov',
}

export enum FondyMerchantType {
  entrepreneur = 'entrepreneur',
  enterprise = 'enterprise',
}

export enum PaymentProvider {
  zhabka = 'zhabka',
  fondy = 'fondy',
}

export interface BaseMerchantMetadata {
  name: string;
}

export interface ZhabkaMerchantMetadata extends BaseMerchantMetadata {
  iban: string;
  type: ZhabkaMerchantType;
  innEdrpou: string;
  coefficient?: number;
}

export interface FondyMerchantMetadata extends BaseMerchantMetadata {
  iban: string;
  type: FondyMerchantType;
  okpo: string;
  ownerEmail?: string;
  ownerPhone?: string;
  url?: string;
}

export interface StripeMerchantMetadata extends BaseMerchantMetadata {
  apiKey: string;
  weSecret: string;
}

export interface LiqPayMerchantMetadata extends BaseMerchantMetadata {
  publicKey: string;
  privateKey: string;
}

export interface Merchant<M = BaseMerchantMetadata> {
  id: number;
  metadata: M;
  cashboxId: number;
  branchIds: (number | string)[];
  active: boolean;
  paymentSystem: PaymentSystem;
  isGlobal: boolean;
  isShortUrl: boolean;
}

export type PaymentLinkRelation = {
  relationId: RelatedDocument['id'];
  relationType: RelatedDocument['type'];
  relationIdLabel: Required<RelatedDocument>['label'];
};

export enum PaymentLinkStatus {
  created,
  viewed,
  waiting,
  paid,
  failed,
  cancelled,
}

export type PaymentLinkStatusKeys = keyof typeof PaymentLinkStatus;

export interface PaymentLink {
  id: number;
  link?: string;
  status: PaymentLinkStatusKeys;
  amount: number;
  relation: PaymentLinkRelation;
  createdById: number;
  createdAt: number;
  description?: string;
  merchantWarning?: boolean;
  client: {
    id: Client['id'];
    fullname: Client['name'];
  };
  merchant: {
    id: Merchant['id'];
    name: Merchant['metadata']['name'];
    paymentSystem: Merchant['paymentSystem'];
  };
}

export type CreatePaymentLinkArgs = {
  clientId: PaymentLink['client']['id'];
  merchantId: PaymentLink['merchant']['id'];
  amount: PaymentLink['amount'];
  description: PaymentLink['description'];
  relationId: PaymentLinkRelation['relationId'];
  relationType: PaymentLinkRelation['relationType'];
};

export interface DeletePaymentLinkArgs {
  paymentId: PaymentLink['id'];
}

export type MerchantArgs<M = BaseMerchantMetadata> = Omit<Merchant<M>, 'id'> & {
  paymentSystem: PaymentSystem;
  deleteOldPayments?: boolean;
};

export interface FondyOptions {
  options?: {
    methods?: string[];
    methods_disabled?: string[];
    card_icons?: string[];
    active_tab?: string;
    fields?: boolean;
    title?: string;
    link?: string;
    full_screen?: boolean;
    button?: boolean;
    email?: boolean;
    logo_url: string | null;
    theme: {
      type: string;
      preset: string;
    };
  };
  params?: {
    token?: string;
  };
}

export interface PaymentMessagesData {
  msgPaymentOrder: string;
  documentSymbol: string;
}
