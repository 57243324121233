// Must be synced with backend. See: remonline/appsettings/constants.py
export const INSTANCE_TYPE = {
  ORDER: 0,
  LEAD: 1,
  TASK: 2,
  CLIENT: 3,
  PRODUCT: 4,
  ASSET: 5,
  SALE: 6,
  REFUND: 7,
  ESTIMATE: 8,
  INVOICE: 9,
} as const;

export const INSTANCE_NAME = {
  ORDER: 'order',
  LEAD: 'lead',
  TASK: 'task',
  CLIENT: 'client',
  PRODUCT: 'product',
  ASSET: 'asset',
  SALE: 'sale',
  [INSTANCE_TYPE.REFUND]: 'refund',
};

export const LEAD_INSTANCE_TYPES = {
  ORDER: 0,
  SALE: 1,
};

export const INSTANCE_TYPE_STRATEGIES = {
  UNDEFINED: 'UNDEFINED',
  FIXED: 'FIXED',
  LAST: 'LAST',
} as const;
