import { ASSETS_DIRECTORY_TYPE_MAPPING } from '@@constants/assets';
import { CATEGORY_TYPES } from '@@constants/categories';
import { CREATE_OPERATION_SOURCE_TYPE } from '@@constants/context';
import {
  CONVERSION_DIRECTION,
  WAREHOUSE_ENTITY_TYPE,
  WAREHOUSE_TYPE,
  WARRANTY_UNIT,
} from '@@constants/warehouse';

import { PaginationRequestArgs } from '@@types/api';
import { Barcode } from '@@types/barcode';
import { DiscountSponsorIds, DiscountTypeIds } from '@@types/discount';
import { EarningPctBasis } from '@@types/earning';
import { EntityType } from '@@types/entity';
import { Cashflow } from '@@types/payment';
import { Access } from '@@types/permissions';
import { Tax } from '@@types/taxes';

export type WarrantyUnit = ExtractValues<typeof WARRANTY_UNIT>;

export type CategoryType = ExtractValues<typeof CATEGORY_TYPES>;

export type ConversionDirection = ExtractValues<typeof CONVERSION_DIRECTION>;

export interface Warehouse {
  id: number;
  title: string;
  branch: number;
  is_global: boolean;
  employees: Access[];
  permissions: number[];
  type: number;
}

export interface Category {
  id: number;
  title: string;
  residue?: number;
  quantity?: number;
  earnings_pct?: number;
  earnings_pct_basis?: number;
  earnings_sum?: number;
  children: Category[];
}

export interface UpdateEntitiesCategory {
  ids: number[];
  category_id: Category['id'];
  type: CategoryType;
}

export interface FlatCategory {
  title: string;
  quantity?: number;
  items: Record<number, FlatCategory>;
}

export interface EntityImage {
  id: number;
  image: string;
  thumbnail: string;
}

export interface AssetOwner {
  id: number;
  deleted: boolean;
  name: string;
}

export interface Sernum {
  id: number;
  code: string;
  isRefunded?: boolean;
}

export interface Asset {
  [key: string]: unknown;
  image: string;
  image_id?: number;
  images: EntityImage[];
  thumbnail: string;
  id: number;
  uid: string;
  title: string;
  color: string;
  state: string;
  cost: number;
  group: string;
  brand: string;
  model: string;
  modification: string;
  description: string;
  year: string;
  owner: AssetOwner | string;
  warehouse: {
    title: string;
    id: number;
    cell_id: number;
    client_id: number;
  };
  group_icon: string;
  deleted_at?: number;
  serial_number?: Sernum;
}

export interface Product {
  [key: string]: unknown;
  id: number;
  code: string;
  title: string;
  sn_accounting: boolean;
  article: string;
  residue: number;
  description: string;
  barcodes: Barcode[];
  images: EntityImage[];
  max_income_price: number;
  min_residue: number;
  image?: string;
  thumbnail?: string;
  image_id?: number;
  taxes: Tax[];
  prices: Record<number, number>;
  warranty: number;
  warranty_period: WarrantyUnit;
  category_id: number;
  category_title: string;
  uom_id: number;
  is_custom_taxes: boolean;
  options?: {
    max_residue: number;
    min_residue: number;
    id: number;
    warehouse: {
      title: string;
      id: number;
    };
  }[];
}

export interface Service {
  id: number;
  title: string;
  uom_id: number;
  barcode: Barcode[];
  category_id: Category['id'];
  cost: number;
  type: EntityType;
  price: number;
  barcodes: Barcode[];
  warranty: number;
  warranty_period: WarrantyUnit;
}

export interface OperationArgs extends Omit<Service, 'id' | 'barcode'> {
  barcodes_ids: Barcode['id'][];
  hidden: boolean;
}

export interface CreateOperationArgs extends OperationArgs {
  earnings_sum?: number;
  earnings_pct?: number;
  earnings_pct_basis: EarningPctBasis;
  source: ExtractValues<typeof CREATE_OPERATION_SOURCE_TYPE>;
}

export type UpdateOperationArgs = Pick<Service, 'id'> & OperationArgs;

export interface InvoiceProduct {
  id: number;
  group: {
    id?: number;
    id_label?: string;
    status_id?: number;
    branch_id?: number;
  };
  entity: number;
  entity_id: number;
  uom_id: number;
  title: number;
  comment: string;
  is_service: boolean;
  is_hidden: boolean;
  type: EntityType;
  price: number;
  cost: number;
  quantity: number;
  discount_pct: number;
  discount_sponsor: DiscountSponsorIds;
  discount_sum: number;
  discount_type: DiscountTypeIds;
  warranty: number;
  warranty_period: WarrantyUnit;
  is_custom_taxes: boolean;
}

export interface PostingProduct extends Product {
  cost: number;
  entity_id: number;
  quantity: number;
  cell_title: string;
  cell_id: number;
  sernums: Sernum[];
}

export interface SernumGoodResidue {
  cell_id: number;
  cell_title: string;
  cost: number;
  sernum_code: string;
  sernum_id: number;
  warehouse_id: number;
  warehouse_title?: string;
  warr_exp?: number;
  groupName?: string;
}

export interface EntityResidue {
  cell_id: number;
  cell_title: string;
  cost: number;
  quantity: number;
}

export interface ProductAndService {
  article: string;
  barcodes: Barcode[];
  code?: string;
  cost: number;
  id: number;
  type: EntityType;
  is_service: boolean;
  price: Record<string, number> | number;
  totalQty: number;
  residue: number;
  image?: string;
  thumbnail?: string;
  image_id?: number;
  sn_accounting: boolean;
  taxes: Tax[];
  title: string;
  total_sum: number;
  uom_id: number;
  warehouse_id: number;
  warehouse_is_global: boolean;
  warehouse_title: string;
  warranty: number;
  warranty_period: WarrantyUnit;
  discount_type?: DiscountTypeIds;
  discount_sponsor?: DiscountSponsorIds;
  discount_sum?: number;
  discount_pct?: number;
  comment?: string;
  residues?: (SernumGoodResidue | EntityResidue)[];
  discount_value?: number;
  is_custom_taxes: boolean;
}

export interface MinifiedProductAndService {
  id: ProductAndService['id'];
  entity_id: ProductAndService['id'];
  type: ProductAndService['type'];
  title: ProductAndService['title'];
}

export type WarehouseResidues = {
  cells: EntityResidue[];
  warehouse_id: number;
};

export interface GetGoodsArgs extends PaginationRequestArgs {
  q?: string;
  warehouse?: number;
}

export interface GetEntitiesArgs extends PaginationRequestArgs {
  category_id?: number;
  exact_category?: boolean;
  availability?: boolean;
  ids?: number[];
}

export interface GetSernumGoodResidueArgs extends PaginationRequestArgs {
  entity_id: number;
  q?: string;
}

export interface GetMoveAvailableAssetsArgs {
  source_warehouse_id?: number;
  client_id?: number;
  branch_id?: number;
  q?: string;
}

export interface GetAllResiduesByEntityArgs {
  entity_ids: number[];
}

export interface FlatCategoryEntityParent {
  id: number;
  previous_id?: number;
}

export interface FlatCategoryEntity {
  id: number;
  parent: FlatCategoryEntityParent;
  parent_id: number;
  title: string;
  type: CategoryType;
  earnings_pct_basis: number;
}

export type CategoryResult = Pick<FlatCategoryEntity, 'id'>;

export type CreateCategoryArgs = Omit<FlatCategoryEntity, 'id'>;

export type UpdateCategoryArgs = Omit<FlatCategoryEntity, 'earnings_pct_basis'>;

export type DeleteCategoryArgs = Pick<FlatCategoryEntity, 'id' | 'type'>;

export interface AssetSpec {
  branches_ids: number[];
  title: string;
  parent_id: number;
  image: string;
  specification_type: ExtractValues<typeof ASSETS_DIRECTORY_TYPE_MAPPING>;
  id: number;
  image_id: string;
}

export interface OutcomeEntity {
  code: string;
  article: string;
  title: string;
  sn_accounting: boolean;
  warranty: number;
  warranty_period: number;
  entity: number;
  prices: Record<string, number>;
  residue: number;
  total_sum: number;
  residues: {
    cell_id: number;
    quantity: number;
    sernum_code?: string;
    sernum_id?: number;
    sum?: number;
    cell_title?: string;
  }[];
  cost: number;
  quantity: number;
  warehouse_id?: number;
  id: number;
  uom_id: number;
}

export interface CountByEntity {
  warehouse_id: number;
  cell_id: number;
  cell_title: string;
  qty: number;
  cost?: number;
}

export interface PrepareOutcomeEntitiesArgs {
  cells: number[];
  warehouse_id: number;
  ids: number[];
}

export interface WarehouseOutcomeArgs {
  id: number;
}

export interface WarehouseOutcomeTransaction {
  id: number;
  created_at: number;
  description: string;
  warehouse_id: number;
  is_system: boolean;
  created_by_id: number;
  id_label: string;
  warehouse_title: string;
  created_by_fullname: string;
  cost: number;
  is_deletable: boolean;
}

export interface WarehouseOutcomeTransactionDetails {
  entity_id: number;
  title: string;
  warehouse_id: number;
  quantities: {
    cell_id: number;
    quantity: number;
    cell_title: string;
  }[];
  cost: number;
  sernums: {
    cell_id: number;
    quantity: number;
    sernum_id: number;
    sernum_code: string;
  }[];
}

export interface SaleProduct {
  id: number;
  entity_id: number;
  type: number;
  is_service: boolean;
  sn_accounting: boolean;
  price: number;
  cost: number;
  title: string;
  comment: string;
  quantity: number;
  product_relation_id: number;
  residues: {
    cell_id: number;
    quantity: number;
  }[];
  taxes: [];
  discount_type: DiscountTypeIds;
  discount_pct: number;
  discount_sum: number;
  discount_sponsor: DiscountSponsorIds;
  warranty: number;
  warranty_period: number;
  entityId: number;
  uom_id: number;
}

export interface WarehouseCell {
  id: number;
  code: string;
  title: string;
  is_default: boolean;
}

export interface WriteOff {
  cellId: number;
  cost: number;
  goodId: number;
  quantity: number;
  repairOrderProductId: number;
  warehouseId: number;
  isRefunded: boolean;
}

export interface SerialWriteOff extends WriteOff {
  sernum_code: string;
  sernum_id: number;
}

export interface DraftWriteOff
  extends Omit<WriteOff, 'goodId' | 'repairOrderProductId'> {
  warehouseQuantity: number;
}

export type WarehouseTypes = ExtractValues<typeof WAREHOUSE_TYPE>;

export type WarehouseEntityTypes = ExtractValues<typeof WAREHOUSE_ENTITY_TYPE>;

export interface DeleteSaleArgs {
  id: number;
  warehouse_id?: number;
  products?: SaleProduct[];
  comment?: string;
}

export interface SerialNumberMigrateItem {
  warehouse_id: number;
  cell_id: number;
  cell_title: string;
  qty: number;
}

export interface CreateHiddenGoodArgs {
  title: string;
  uom_id: number;
}

export interface MoveAssetsArgs {
  asset_ids: number[];
  target_warehouse_id?: number;
  cell_ids?: number[];
  description?: string;
  client_id?: number;
  employee_id?: number;
}

export interface GetCategoriesArgs {
  with_zero_residue: boolean;
  type: CategoryType;
}

export enum ResidueAvailabilityType {
  all,
  available,
  unavailable,
  belowMin,
  withoutBarcode,
}

export type BatchItem = {
  residue: number;
  batch_id: number;
  warehouse_id: number;
  cell_id: number;
  cell_title: string;
  sernum_id: number;
  sernum_code: string;
};

export type WarehouseBatch = {
  related_document_id: number;
  warranty_date?: number;
  document_id: string;
  document_datetime: number;
  relation_id: number;
  relation_date: number;
  relation_type: number;
  relation_id_label: string;
  client_id: number;
  client_name: string;
  total_quantity: number;
  total_sum: number;
  sn_accounting: boolean;
  total_residue: number;
  items: BatchItem[];
};
export interface ConversionDetailsData {
  created_at: number;
  entities: {
    id: number;
    title: string;
    serial_number_id: number;
    serial_number_code: string;
  }[];
  conversion_id_label: string;
  created_by_fullname: string;
  asset_id: number;
  asset_uid: string;
  asset_brand: string;
  asset_group: string;
  asset_model: string;
  asset_modification: string;
  direction: ConversionDirection;
  payment?: { id: number; custom_created_at: number; is_deleted: boolean }[];
}

export interface MoveTransactionResidue {
  cell_id: number;
  quantity: number;
  sernum_id?: number;
  sernum_code?: string;
  cell_title?: string;
  cost?: number;
  warehouse_id?: number;
}

export interface GetMoveTransactionData {
  id: number;
  warehouse_id: number;
  created_at: number;
  description: string;
  created_by_id: number;
  id_label: string;
  warehouse: string;
  target_warehouse: string;
  created_by: string;
  target_warehouse_id: string;
}

export interface GetMoveTransactionDetailsData {
  entity_id: number;
  to_cell_id: number;
  to_cell_title: string;
  title: string;
  uom_id: number;
  prices: Record<string, number>;
  product_relation_id: number;
  sn_accounting: boolean;
  residues: MoveTransactionResidue[];
  warehouse_title: string;
  cost: number;
  article?: string;
  code?: string;
}

export interface TransformedMoveTransactionDetails
  extends Omit<GetMoveTransactionDetailsData, 'to_cell_id' | 'to_cell_title'> {
  id: number;
  cell_id: number;
  cell_title: string;
  quantity: number;
  warehouse_id: number;
  isDisabled?: boolean;
}

export interface MoveEntity {
  id: number;
  cell_id?: number;
  cell_title?: string;
  quantity: number;
  warehouse_id: number;
  residues: MoveTransactionResidue[];
  code: string;
  article: string;
  title: string;
  uom_id: number;
  sn_accounting: boolean;
  warehouse_title?: string;
  cost?: number;
  _uniqueId?: string | number;
}

export interface MoveEntitiesArgs {
  entities: MoveEntity[];
  sourceWarehouse: number;
  targetWarehouse: number;
  description: string;
}

export interface ConversionHistoryData {
  conversionId: number;
  conversionIdLabel: string;
  createdByFullname: string;
  conversionCreatedAt: number;
  assetId: number;
  assetUid: string;
  assetGroup: string;
  assetBrand: string;
  assetModel: string;
  assetModification: string;
  assetDescription: string;
  entities: {
    id: number;
    title: string;
    serialNumberId: number;
    serialNumberCode: string;
  }[];
  direction: ConversionDirection;
  payment?: {
    id: number;
    customCreatedAt: number;
    isDeleted: boolean;
    paymentLabel: string;
  }[];
}

export interface AssetToProductConversionArgs {
  warehouse_id: number;
  supplier_id: number;
  products: PostingProduct[];
  cashflow?: Cashflow;
  asset_id: number;
}

export interface AssetToProductConversion {
  id: number;
  created_at: number;
  transaction_id: number;
  transaction_id_label: string;
  created_by: {
    pk: number;
  };
  asset: {
    pk: Asset['id'];
  };
  branch: {
    pk: number;
  };
  supplier: {
    pk: number;
  };
}
