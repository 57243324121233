import {
  init,
  reactRouterV5BrowserTracingIntegration,
  replayIntegration,
} from '@sentry/react';

import historyConfig from '@@router/history';

export const initSentry = () => {
  if (!process.env.SENTRY_DSN) {
    return;
  }

  init({
    dsn: process.env.SENTRY_DSN,
    release: process.env.VERSION,
    integrations: [
      replayIntegration(),
      reactRouterV5BrowserTracingIntegration({
        history: historyConfig.history,
      }),
    ],
    // Visible redux state context depth
    normalizeDepth: 5,
    // Capture Replay for 0% of all sessions,
    // plus for 10% of sessions with an error
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.5,
  });
};
