import { Component, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { ErrorBoundary } from '@sentry/react';
import i18next from 'i18next';
import PropTypes from 'prop-types';

import Fallback from '@@components/Fallback';
import Spinner from '@@components/Spinner';

import { checkAuth, refreshToken } from '@@api/auth';
import { getAllFeatureFlags } from '@@api/featureFlags';
import { getEnvironment } from '@@api/settings/legacy';
import { BASE_NAMESPACE } from '@@constants/i18n';
import { pages } from '@@constants/pages';
import { FeatureFlagsProvider } from '@@contexts/FeatureFlagsContext';
import { getNamespaces, setGlobalTranslateFn } from '@@helpers/i18n';
import { getRefreshToken } from '@@helpers/transport/tokens';
import {
  finishUpdateAppInfo as finishUpdateAppInfoAction,
  setAppInfo as setAppInfoAction,
} from '@@redux/appInfo/slice';
import { initI18next } from '@@services/i18n';
import { getBodyGaTag, getHeadGaTag, initDataLayer } from '@@snippets/GaTag';

import * as styles from './shared.module.scss';

const Entrance = lazy(() => import('./Entrance'));
const App = lazy(() => import('./App'));
const Expired = lazy(() => import('./Expired'));
const Staff = lazy(() => import('./Staff'));
const ChangePassword = lazy(() => import('./ChangePassword'));

class IndexRouter extends Component {
  static propTypes = {
    appInfo: PropTypes.objectOf(
      PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    ),
    setAppInfo: PropTypes.func.isRequired,
    finishUpdateAppInfo: PropTypes.func.isRequired,
    history: PropTypes.objectOf(PropTypes.any).isRequired,
  };

  static defaultProps = {
    appInfo: {},
  };

  constructor(props) {
    super(props);

    this.state = {
      page: null,
      flags: [],
    };
  }

  componentDidMount() {
    this.initReactRouterLinks();
    this.updatePage();
  }

  componentDidUpdate(prevProps) {
    const { appInfo } = this.props;

    if (!prevProps.appInfo.loading && appInfo.loading) {
      this.updatePage();
    }
  }

  checkToken = async (data) => {
    const refresh_token = getRefreshToken();

    if (!data.isAuthenticated && refresh_token) {
      const { isAuthenticated, isExpired, pswErrors } = await refreshToken({
        refresh_token,
      });

      return {
        ...data,
        pswErrors,
        isExpired,
        isAuthenticated,
      };
    }

    return data;
  };

  getPage = (data) => {
    const {
      isAuthenticated,
      isInUser,
      isStaff,
      isExpired,
      signupStep,
      pswErrors,
    } = data;

    if (!isAuthenticated || signupStep) {
      return pages.entrance;
    }

    if (isStaff && !isInUser) {
      return pages.staff;
    }

    if (isExpired) {
      return pages.expired;
    }

    if (pswErrors) {
      return pages.changePassword;
    }

    return pages.app;
  };

  initState = (page) => {
    const { finishUpdateAppInfo } = this.props;

    this.setState({
      page,
    });

    finishUpdateAppInfo();
  };

  initGaTag = (page, ENV) => {
    if (page.fileName !== pages.staff.fileName) {
      initDataLayer();
      const headScript = document.createElement('script');
      const bodyScript = document.createElement('noscript');

      headScript.innerHTML = getHeadGaTag(ENV);
      headScript.async = true;
      bodyScript.innerHTML = getBodyGaTag(ENV);

      document.head.append(headScript);
      document.body.append(bodyScript);
    }
  };

  initI18n = async () => {
    const {
      LANDING,
      NICHE_CATEGORY,
      TRANSLATIONS,
      TRANSLATIONS_NAMESPACES,
    } = require('app/Env/settings');

    const {
      LOCALE,
      WEB_APP_BRAND,
      MOBILE_APP_BOSS_BRAND,
      MOBILE_APP_WORK_ORDERS_BRAND,
    } = require('interface/System');

    const ns = getNamespaces({
      landingNs: LANDING,
      nicheNs: NICHE_CATEGORY,
      baseNs: BASE_NAMESPACE,
      existNamespaces: TRANSLATIONS_NAMESPACES,
    });

    if (!window.__) {
      await initI18next({
        locale: LOCALE,
        loadPath: TRANSLATIONS,
        ns,
        defaultNS: ns[0],
        setGlobalTranslateFn,
        defaultVariables: {
          _brand_: WEB_APP_BRAND,
          _appBrand_: MOBILE_APP_BOSS_BRAND,
          _appOrdersBrand_: MOBILE_APP_WORK_ORDERS_BRAND,
        },
      });
    } else {
      await i18next.loadNamespaces(ns);
      i18next.setDefaultNamespace(ns[0]);
    }
  };

  initApp = async (page, configs, flags) => {
    const { ENV, CONFIG, USER } = configs;

    this.setState({ flags });
    this.initGaTag(page, ENV);

    require('interface/System').setState(CONFIG);
    require('app/Env/settings').setState(ENV);
    require('interface/User').setState(USER);

    await this.initI18n();

    this.initState(page.fileName);
  };

  updatePage = async () => {
    const { setAppInfo } = this.props;

    const authData = await this.checkToken(await checkAuth());

    const page = this.getPage(authData);

    setAppInfo(authData);

    const [configs, flags] = await Promise.all([
      getEnvironment(),
      getAllFeatureFlags(),
    ]);

    await this.initApp(page, configs, flags);
  };

  initReactRouterLinks = () => {
    jQuery('body').on('click', 'a', (e) => {
      const { history } = this.props;
      const hrefAttr = e.currentTarget.getAttribute('href');
      const targetAttr = e.currentTarget.getAttribute('target');
      const isReactLink = e.currentTarget.getAttribute('data-react') === 'true';

      if (
        (!targetAttr || targetAttr === '_self') &&
        !isReactLink &&
        hrefAttr &&
        hrefAttr.charAt(0) === '/' &&
        hrefAttr.charAt(1) !== '/'
      ) {
        e.preventDefault();
        history.push(hrefAttr);
      }
    });
  };

  asyncModule = () => {
    const { page } = this.state;

    switch (page) {
      case pages.entrance.fileName: {
        return Entrance;
      }
      case pages.app.fileName: {
        return App;
      }
      case pages.expired.fileName: {
        return Expired;
      }
      case pages.changePassword.fileName: {
        return ChangePassword;
      }
      default: {
        return Staff;
      }
    }
  };

  getFallback({ resetError }) {
    return <Fallback resetError={resetError} />;
  }

  render() {
    const { appInfo } = this.props;
    const { page, flags } = this.state;

    const loader = (
      <div className={styles.loading}>
        <Spinner size='big' />
      </div>
    );

    if (!page) {
      return loader;
    }

    const Module = this.asyncModule();

    return (
      <ErrorBoundary fallback={this.getFallback}>
        <FeatureFlagsProvider flags={flags}>
          <Suspense fallback={loader}>
            <Module pageId={page} isStaff={appInfo.isStaff} />
          </Suspense>
        </FeatureFlagsProvider>
      </ErrorBoundary>
    );
  }
}

const mapStateToProps = ({ appInfo }) => ({ appInfo });

const mapDispatchToProps = (dispatch) => ({
  setAppInfo: (payload) => dispatch(setAppInfoAction(payload)),
  finishUpdateAppInfo: () => dispatch(finishUpdateAppInfoAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndexRouter);
