import { FLETCHER_PREFIX, FLETCHER_TYPES } from '@@constants/fletcher';

export const cachedTables = [
  'clients_table',
  'residue_table',
  'shop_table',
  'warehouse_posting_table',
  'warehouse_outcome_table',
  'warehouse_move_table',
  'reports/calls/telephony',
  'payments/invoices',
  'payments/refunds',
  'sup_refund_table',
  'staff/companies',
  'settings/services-pricelist',
  'products_table',
  '/settings/directory/asset/group',
  '/settings/directory/asset/brand',
  '/settings/directory/asset/model',
  '/settings/directory/asset/modification',
  'leads_table',
  'warehouse_assets_table',
  'settings/taxes',
  'settings/taxes/exceptions',
  '/payments/payroll-accruals',
  '/payments/payroll-calculation',
  'chat/channel',
  'warehouse/conversions',
];

export const sortAndFieldsCachedTables = [
  'reports/finance/cashflow-categories',
  'reports/finance/shop-profit',
  'reports/finance/total-cash',
  'reports/finance/orders-profit',
  'reports/orders/engineers',
  'reports/orders/services',
  'reports/orders/incomed',
  'reports/orders/outcomed',
  'reports/orders/current',
  'reports/warehouse/goods-flow',
  'reports/warehouse/residue',
  'reports/warehouse/outcome',
  'reports/warehouse/requiring-purchase',
  'reports/calls/sms',
  'reports/calls/deferred-sms',
  'reports/other/review',
  'reports/other/authorization-report',
  'reports/other/ad_campaign_report',
  'reports/leads/all-leads',
  'reports/leads/lead-conversion',
  'reports/leads/employee_performance',
  'reports/leads/lost-leads',
  'stocktakings_table',
  'analytics/assortment',
  'settings/warehouses/products',
  'settings/warehouses/assets',
  'leads_table',
  'warehouse_assets_table',
  'settings/taxes',
  'orders/dialog/invoices',
  'orders/dialog/payments',
  'orders/dialog/payments/legacy',
  'orders/dialog/ro-pay-requests',
  'settings/taxes/exceptions',
  'settings/employee',
  '/settings/directory/asset/group',
  '/settings/directory/asset/brand',
  '/settings/directory/asset/model',
  '/settings/directory/asset/modification',
  '/payments/payment-links',
  'myCompany/bundles',
  'warehouse/conversions',
  `${FLETCHER_TYPES.ORDER_PRODUCTS}${FLETCHER_PREFIX}`,
  `${FLETCHER_TYPES.SERVICE}${FLETCHER_PREFIX}`,
  `${FLETCHER_TYPES.PRODUCTS}${FLETCHER_PREFIX}`,
  `${FLETCHER_TYPES.BUNDLE}${FLETCHER_PREFIX}`,
];

export const sortAndFieldsCachedFletcherTables = [
  `${FLETCHER_TYPES.ORDER_PRODUCTS}${FLETCHER_PREFIX}`,
  `${FLETCHER_TYPES.SERVICE}${FLETCHER_PREFIX}`,
  `${FLETCHER_TYPES.PRODUCTS}${FLETCHER_PREFIX}`,
  `${FLETCHER_TYPES.BUNDLE}${FLETCHER_PREFIX}`,
];

export const nestedTables = [
  '/reports/orders/outcomed:nested',
  'reports/finance/cashflow-categories:nested',
  '/reports/orders/engineers:nested',
  '/reports/orders/services:nested',
  'reports/warehouse/outcome:nested',
  'settings/statuses/leads:nested',
  'settings/statuses/orders:nested',
  'myCompany/bundles:nested',
];

export const fullCachedTables = ['orders_table'];
