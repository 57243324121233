export const FLETCHER_TYPES = {
  SERVICE: 'service',
  PRODUCTS: 'products',
  SERVICE_SIMPLE: 'service-simple',
  SERVICE_STICKER: 'service-sticker',
  RESIDUE_STICKER: 'residue-sticker',
  PRODUCT_STICKER: 'product-sticker',
  ORDER_PRODUCTS: 'order-products',
  FEATURE_FLAG: 'feature-flag',
  MOVE_ASSETS: 'move-assets',
  SALES: 'sales',
  WRITE_OFF: 'write-off',
  BUNDLE: 'bundle',
  BUNDLE_STIKERS: 'bundle-stickers',
  CLIENT_REFUND: 'client-refund',
  CONVERSION_TO_PRODUCT: 'conversion-to-product',
  TRANSFER_GOODS: 'transfer-goods',
  BOOKINGS: 'bookings',
} as const;

export const FLETCHER_PREFIX = ':fletcher';
