import { EXEPTIONAL_COMMISSION_TYPE } from '@@constants/earnings';
import { DAY_TYPE } from '@@constants/workSchedule';

import { SalaryCommissionRule, SalaryEvent, SalaryType } from '@@types/salary';

export enum EarningPctBasis {
  profit,
  price,
}

export interface Rule {
  orderTypeIds: number[];
  statusIds: number[];
  calculationTime: number;
  urgency: number;
  event: number;
}

export interface ExceptionalCommission {
  id: number;
  objectId: number;
  title: string;
  earningsSum: number;
  earningsPctBasis: EarningPctBasis;
  earningsPct: number;
  type: ExtractValues<typeof EXEPTIONAL_COMMISSION_TYPE>;
}

export interface EarningDetails {
  id: number;
  datetime: number;
  event: SalaryEvent;
  amount: number;
  rule: SalaryCommissionRule & { title: string };
  exceptionalCommission: ExceptionalCommission;
  dayOffRule: {
    dayOffType: ExtractValues<typeof DAY_TYPE>;
    salaryType: SalaryType;
  };
}
