// @ts-expect-error ignore types for react-router-redux
// should be removed in future
// if @types/react-router-redux@4.0.30 is installed
// it breaks other types due to a version conflict.
import { routerReducer } from 'react-router-redux';
import { combineReducers } from '@reduxjs/toolkit';
import { persistCombineReducers } from 'redux-persist';
import { createResponsiveStateReducer } from 'redux-responsive';

import chatServiceApi from '@@api/chatService/rtk';
import api from '@@api/rtk';
import webhookServiceApi from '@@api/webhookService/rtk';
import {
  DESKTOP_BREAKPOINT,
  EXTRA_SMALL_BREAKPOINT,
  HUGE_BREAKPOINT,
  INTERMEDIATE_BREAKPOINT,
  INTERMEDIATE_MEDIUM_BREAKPOINT,
  INTERMEDIATE_SMALL_BREAKPOINT,
  LARGE_BREAKPOINT,
  MEDIUM_BREAKPOINT,
  SMALL_BREAKPOINT,
  TINY_BREAKPOINT,
} from '@@constants/ui';

import adCampaigns from './adCampaigns/slice';
import analytics from './analytics/slice';
import appInfo from './appInfo/slice';
import auth from './auth/slice';
import badges from './badges/slice';
import branches from './branches/slice';
import callCards from './callCards/slice';
import chats from './chats/slice';
import dndTree from './dndTree/slice';
import dropdown from './dropdown/slice';
import fletcher from './fletcher/slice';
import modals from './modals/slice';
import notifications from './notifications/slice';
import onboarding from './onboarding/slice';
import order from './order/slice';
import resources from './resources/slice';
import schedule from './schedule/slice';
import tables from './tables/slice';
import tasks from './tasks/slice';
import ui from './ui/slice';
import { persistConfig } from './persist';

const reducers = {
  [api.reducerPath]: api.reducer,
  [chatServiceApi.reducerPath]: chatServiceApi.reducer,
  [webhookServiceApi.reducerPath]: webhookServiceApi.reducer,
  ui,
  tables,
  schedule,
  branches,
  appInfo,
  notifications,
  onboarding,
  dndTree,
  dropdown,
  fletcher,
  modals,
  auth,
  analytics,
  callCards,
  badges,
  tasks,
  adCampaigns,
  resources,
  order,
  chats,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  routing: routerReducer as any,
  viewport: createResponsiveStateReducer({
    tiny: TINY_BREAKPOINT,
    extraSmall: EXTRA_SMALL_BREAKPOINT,
    intermediateSmall: INTERMEDIATE_SMALL_BREAKPOINT,
    small: SMALL_BREAKPOINT,
    intermediateMedium: INTERMEDIATE_MEDIUM_BREAKPOINT,
    medium: MEDIUM_BREAKPOINT,
    intermediate: INTERMEDIATE_BREAKPOINT,
    desktop: DESKTOP_BREAKPOINT,
    large: LARGE_BREAKPOINT,
    huge: HUGE_BREAKPOINT,
  }),
};

export const rootTypedReducer = combineReducers(reducers);
export const rootReducer = persistCombineReducers(persistConfig, reducers);
